import { clearStore, ColorString } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** The type of reference plane used for an analysis */
export enum ReferencePlaneType {
  /** Reference plane is computed by best-fit of the selected points */
  flatness = "flatness",

  /** Reference plane is set to a level plane at a fixed elevation */
  elevation = "elevation",

  /** Reference plane is set to a level plane at elevation of the bestfit center */
  levelness = "levelness",

  /** Reference plane is contrained to vertical */
  plumbness = "plumbness",
}

/** Colormap defined as a list of value and color pairs  */
export type PointCloudAnalysisColormap = Array<{
  /** Scaled value within range of [0, 1] */
  value: number;
  /** Color associated with the value */
  color: ColorString;
}>;

export type PointCloudAnalysisPlane = {
  /** The normal of the plane */
  normal: Vector3Tuple;
  /** A point on the plane */
  point: Vector3Tuple;
};

/** Label associated with a point cloud analysis */
export type PointCloudAnalysisLabel = {
  /** The unique id of this label */
  id: GUID;

  /** The position of the label */
  position: Vector3Tuple;
};

export type PointCloudAnalysis = {
  /** The unique id of this analysis */
  id: GUID;

  /** The polygon used to select points for analysis */
  polygonSelection: Vector3Tuple[];

  /** Tolerance (+/-) value for the analysis */
  tolerance: number;

  /** The id of the point cloud iElement this analysis originated from */
  parentId: GUID;

  /** The type of reference plane used for this analysis */
  referencePlaneType: ReferencePlaneType;

  /** Colormap used to colorize point according to its deviation to the reference plane */
  colormap: PointCloudAnalysisColormap;

  /** The elevation for the level reference plane */
  elevation: number;

  /** Show reference plane when analysis is active */
  showReferencePlane?: boolean;

  /** if true analysis is visible */
  isVisible: boolean;

  /** Fitted plane from the user selected point cloud region */
  fittedPlane?: PointCloudAnalysisPlane;

  /** Fitted plumb plane from user selected point cloud region */
  fittedPlumbPlane?: PointCloudAnalysisPlane;

  /** if true, the analysis is edited since last save */
  isDirty: boolean;

  /** The labels associated with this analysis */
  labels: PointCloudAnalysisLabel[];
};

type PointCloudAnalysisToolState = {
  /** For each specific point cloud, identified by its GUID, store the list of analyses associated to it */
  analyses: Record<GUID, PointCloudAnalysis[]>;

  /** The active analysis id or undefined if none*/
  activeAnalysisId?: GUID;

  /** True if an analysis is being created */
  isAnalysisBeingCreated: boolean;

  /** Number of analysis created this session */
  numberOfSessionAnalyses: number;

  /** True if labels are being created */
  isLabelsBeingCreated: boolean;
};

const initialState: PointCloudAnalysisToolState = {
  analyses: {},
  activeAnalysisId: undefined,
  isAnalysisBeingCreated: false,
  numberOfSessionAnalyses: 0,
  isLabelsBeingCreated: false,
};

const pointCloudAnalysisToolSlice = createSlice({
  name: "pointCloudAnalysisTool",
  initialState,
  reducers: {
    addAnalysis(
      state,
      action: PayloadAction<{
        pointCloudID: GUID;
        analysis: PointCloudAnalysis;
      }>,
    ) {
      state.numberOfSessionAnalyses++;
      if (!(action.payload.pointCloudID in state.analyses)) {
        state.analyses[action.payload.pointCloudID] = [];
      }

      state.analyses[action.payload.pointCloudID].push(action.payload.analysis);
    },
    removeAnalysis(state, action: PayloadAction<GUID>) {
      const analysis = findAnalysis(state.analyses, action.payload);
      if (analysis) {
        if (state.activeAnalysisId === analysis.id) {
          state.activeAnalysisId = undefined;
        }
        const analyses = state.analyses[analysis.parentId];
        state.analyses[analysis.parentId] = analyses.filter(
          (a) => a.id !== action.payload,
        );
      }
    },
    setAnalysisTolerance(
      state,
      action: PayloadAction<{ analysisId: GUID; tolerance: number }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.tolerance = action.payload.tolerance;
      }
    },
    setActiveAnalysisId(state, action: PayloadAction<GUID | undefined>) {
      state.activeAnalysisId = action.payload;
    },
    setIsAnalysisBeingCreated(state, action: PayloadAction<boolean>) {
      state.isAnalysisBeingCreated = action.payload;
    },
    setIsLabelsBeingCreated(state, action: PayloadAction<boolean>) {
      state.isLabelsBeingCreated = action.payload;
    },
    setAnalysisReferencePlaneType(
      state,
      action: PayloadAction<{
        analysisId: GUID;
        referencePlaneType: ReferencePlaneType;
      }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.referencePlaneType = action.payload.referencePlaneType;
      }
    },
    setAnalysisColormap(
      state,
      action: PayloadAction<{
        analysisId: GUID;
        colormap: PointCloudAnalysisColormap;
      }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.colormap = action.payload.colormap;
      }
    },

    setAnalysisElevation(
      state,
      action: PayloadAction<{ analysisId: GUID; elevation: number }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.elevation = action.payload.elevation;
      }
    },
    setAnalysisShowReferencePlane(
      state,
      action: PayloadAction<{ analysisId: GUID; showReferencePlane: boolean }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.showReferencePlane = action.payload.showReferencePlane;
      }
    },

    setAnalysisVisibility(
      state,
      action: PayloadAction<{ analysisId: GUID; visible: boolean }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.isVisible = action.payload.visible;
      }
    },

    setAnalysisDirtyFlag(
      state,
      action: PayloadAction<{ analysisId: GUID; dirty: boolean }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.isDirty = action.payload.dirty;
      }
    },

    addAnalysisLabel(
      state,
      action: PayloadAction<{
        analysisId: GUID;
        label: PointCloudAnalysisLabel;
      }>,
    ) {
      const analysis = findAnalysis(state.analyses, action.payload.analysisId);
      if (analysis) {
        analysis.labels.push(action.payload.label);
        analysis.isDirty = true;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const pointCloudAnalysisToolReducer =
  pointCloudAnalysisToolSlice.reducer;

export const {
  addAnalysis,
  removeAnalysis,
  setAnalysisTolerance,
  setActiveAnalysisId,
  setIsAnalysisBeingCreated,
  setIsLabelsBeingCreated,
  setAnalysisColormap,
  setAnalysisReferencePlaneType,
  setAnalysisElevation,
  setAnalysisShowReferencePlane,
  setAnalysisVisibility,
  setAnalysisDirtyFlag,
  addAnalysisLabel,
} = pointCloudAnalysisToolSlice.actions;

/**
 *  @returns the analysis matching a given id
 *  @param analyses List of analyses per point cloud
 *  @param analysisId The id of the analysis to get
 */
export function findAnalysis(
  analyses: Record<GUID, PointCloudAnalysis[]>,
  analysisId: GUID,
): PointCloudAnalysis | undefined {
  for (const listOfAnalyses of Object.values(analyses)) {
    const analysis = listOfAnalyses.find((a) => a.id === analysisId);
    if (analysis) {
      return analysis;
    }
  }
}
