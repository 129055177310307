import { IS_PROD } from "@/utils/env-constants";

/** Metadata required for each Sphere Viewer feature */
export type Feature = {
  /** A description of what this feature should enable/control */
  description: string;

  /** True to enable this feature by default in dev environments */
  isPreviewFeature: boolean;

  /** True to allow users to enable this feature manually */
  canBeUserEnabled: boolean;

  /**
   * True is an optional value can be associated with the enabled feature via the url (e.g. ?featureKey=value).
   * False if potential value in the url should be ignored.
   */
  supportValueInUrl: boolean;
};

/**
 * An object with all current features and their metadata
 *
 * NOTE: When you add a new feature flag please keep them sorted alphabetically to
 * reduce the risk of merge conflicts
 */
export const FEATURES = Object.freeze({
  AddArea: {
    canBeUserEnabled: true,
    description: "Add new area/sheet to existing project",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
  Aligned360: {
    canBeUserEnabled: false,
    description:
      "Enable features that expect the 360 to be aligned (minimap user indicator, 360 - point cloud transition)",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  AreaNavigation: {
    canBeUserEnabled: true,
    description: "Enable the new project sidepanel with the area selection",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
  ChangeCadCs: {
    canBeUserEnabled: true,
    description: "Change the CAD Model's coordinates system",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
  CloudToCadHeatmapAvailable: {
    canBeUserEnabled: true,
    description: "Enable Heatmap feature button in WalkMode",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  ColorMapAnalysis: {
    canBeUserEnabled: true,
    description: "Enable point cloud color mapping analysis",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  CreateArea: {
    canBeUserEnabled: true,
    description: "Allow users to create new areas",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  DevTools: {
    canBeUserEnabled: false,
    description: "Enables debug tools that only developers should see",
    isPreviewFeature: !IS_PROD,
    supportValueInUrl: false,
  },
  FloorplanGeneration: {
    canBeUserEnabled: true,
    description:
      "Allows user to trigger the floorplan generation from the export tool",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  FullTree: {
    canBeUserEnabled: true,
    description: "Render the entire project tree and not the filtered version",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
  InspectOrthoCam: {
    canBeUserEnabled: true,
    description:
      "Use an orthographic instead of perspective 3D camera to inspect registration results",
    isPreviewFeature: false,
    supportValueInUrl: true,
  },
  LargeCadModel: {
    canBeUserEnabled: true,
    description: "Enable the support for large CAD model",
    isPreviewFeature: true,
    // mandatory url value is the memory limit in MB (feature is ignored without a value)
    supportValueInUrl: true,
  },
  Localize: {
    canBeUserEnabled: true,
    description:
      "Enable the translation of the application in a different language",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  MeshSupport: {
    canBeUserEnabled: false,
    description: "Enable mesh support (dollhouse rendering and animations)",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  MultiCloudRegistration: {
    canBeUserEnabled: true,
    description: "Enable multi-cloud registration",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  OriginalPcRendering: {
    canBeUserEnabled: true,
    description: "Enable a rendering profile with no effects",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
  OrthoPhoto: {
    canBeUserEnabled: true,
    description: "Enables the creation of orthophotos in the export tool",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  PointerFeedback: {
    canBeUserEnabled: true,
    description:
      "Enable a feedback on pointer events in the 3D view over 3D data",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
  PreAlignment: {
    canBeUserEnabled: true,
    description: "Enable automatic pre-alignment for orbis datasets",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  RegistrationDev: {
    canBeUserEnabled: true,
    description: "Enable registration features for developers",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  ThirdPartyAnnotation: {
    canBeUserEnabled: true,
    description: "Enable the use of third party annotations",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  TransformModel: {
    canBeUserEnabled: true,
    description: "Enable editing transformation on CAD Model",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
  UseCloudToBimEndPoint: {
    canBeUserEnabled: true,
    description:
      "Use cloud to bim alignment end point for cloud-to-cad alignment",
    isPreviewFeature: true,
    supportValueInUrl: false,
  },
  VisualRegistration: {
    canBeUserEnabled: true,
    description:
      "Allow users to manually align point clouds in the multi cloud view",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
  WalkAnimation: {
    canBeUserEnabled: true,
    description: "Enable the use of the Sequence Walk Animation in WalkMode",
    isPreviewFeature: false,
    supportValueInUrl: false,
  },
}) satisfies Readonly<Record<string, Feature>>;

/** A FeatureFlag is one of the keys of the FEATURE object */
export type FeatureFlag = keyof typeof FEATURES;

/**
 * An enum like object that contains a key/value pair for each valid Features to keep
 * the features code compatible with the previous implementation
 */
export const Features = computeFeaturesEnum();

/**
 * @returns all the valid features
 */
export function allFeatures(): FeatureFlag[] {
  return Object.keys(Features).filter(isFeatureFlag);
}

/**
 * @returns all the preview features
 */
export function previewFeatures(): FeatureFlag[] {
  return Object.entries(FEATURES)
    .filter(([, value]) => value.isPreviewFeature)
    .map(([key]) => key)
    .filter(isFeatureFlag);
}

/**
 * @returns true if the feature does allow optional value in the url; false means any value in the url would be ignored
 * @param feature FeatureFlag of the feature being tested
 */
export function isFeatureSupportingValue(feature: FeatureFlag): boolean {
  return FEATURES[feature].supportValueInUrl;
}

/**
 * @returns all the features the user can enable manually
 */
export function userControlledFeatures(): FeatureFlag[] {
  return Object.entries(FEATURES)
    .filter(([, value]) => value.canBeUserEnabled)
    .map(([key]) => key)
    .filter(isFeatureFlag);
}

/**
 * Type Guard to check if a string is one of the Features
 *
 * @param string to test
 * @returns true if it's one of the valid Features
 */
export function isFeatureFlag(string: string): string is FeatureFlag {
  return Object.keys(Features).includes(string);
}

/** An object that act like an Enum, where each value is both the key and the value of the object */
type FeaturesEnum = {
  [key in keyof typeof FEATURES]: keyof typeof FEATURES;
};

/** @returns an EnumLike object with a key for each Feature in the FEATURES object */
function computeFeaturesEnum(): FeaturesEnum {
  // Object.keys does not narrow down a string to a key so we need to use a type assertion
  // the validity of the returned object is checked in the Features unit test
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.keys(FEATURES).reduce(
    (prev, key) => Object.assign(prev, { [key]: key }),
    {},
  ) as FeaturesEnum;
}

/** Describe a query to enable a feature in the url. */
type FeatureQuery = {
  /** feature being enabled. */
  featureFlag: FeatureFlag;
  /** actual query included in the url (the url is NOT case sensitive = urlQuery might be different from featureFlag). */
  urlQuery: string;
  /** Optional value associate with the feature. */
  value: string | null;
};

/**
 * Extract the list of features and optional values from the url (only for feature supporting values).
 *
 * @param searchParams URLSearchParams storing the whole url
 * @returns a list of triplet with enabled FeatureFlag, the feature name in the url, and the optional value
 * The url is not case sensitive, so the feature name in the url may have different case than the FeatureFlag.
 */
export function parseFeaturesAndValuesFromUrl(
  searchParams: URLSearchParams,
): FeatureQuery[] {
  // map storing the case sensitive FeatureFlag from each lower case key (required because the url is not case sensitive)
  const lowerCaseFeatureFlags = new Map<string, FeatureFlag>();
  for (const feature of userControlledFeatures()) {
    lowerCaseFeatureFlags.set(feature.toLowerCase(), feature);
  }

  const urlParams = new Array<FeatureQuery>();
  searchParams.forEach((urlValue, urlQuery) => {
    const lowerCaseKey = urlQuery.toLowerCase();
    const featureFlag = lowerCaseFeatureFlags.get(lowerCaseKey);
    if (featureFlag !== undefined) {
      urlParams.push({
        featureFlag,
        urlQuery,
        value:
          urlValue === "" || !isFeatureSupportingValue(featureFlag)
            ? null
            : urlValue,
      });
    }
  });

  return urlParams;
}
