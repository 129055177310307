import {
  getRegistrationReportError,
  MultiRegistrationReport,
  RegistrationError,
} from "@/registration-tools/utils/multi-registration-report";
import { MaybeLoading } from "@faro-lotv/foundation";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { ShowQualityReportButton } from "../ui/show-quality-report-button";
import { PublishButton } from "./inspect-and-publish-publish-button";

interface InspectAndPublishSidebarButtonsProps {
  /** The revision to publish */
  revision: RegistrationRevision;

  /** The registration report of the active revision. */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Whether the report is currently open */
  isReportOpen: boolean;

  /** Callback, when the report should be opened/closed */
  onToggleReport(shouldOpen: boolean): void;
}

/** @returns the buttons for the data preparation sidebar in the inspect-and-publish step */
export function InspectAndPublishSidebarButtons({
  revision,
  registrationReport,
  onToggleReport,
}: InspectAndPublishSidebarButtonsProps): JSX.Element {
  const registrationError = getRegistrationReportError(registrationReport);

  let publishButtonDisabled;
  let publishButtonWithWarning;

  switch (registrationError) {
    case RegistrationError.NoRegistration:
      publishButtonDisabled = true;
      publishButtonWithWarning = true;
      break;
    case RegistrationError.PublishFailed:
    case RegistrationError.MergeFailed:
    case RegistrationError.OtherError:
      publishButtonDisabled = true;
      publishButtonWithWarning = true;
      break;
    case RegistrationError.MissingConnections:
      publishButtonDisabled = false;
      publishButtonWithWarning = true;
      break;
    case RegistrationError.NoError:
      publishButtonDisabled = false;
      publishButtonWithWarning = false;
      break;
  }

  return (
    <>
      <ShowQualityReportButton
        revision={revision}
        registrationReport={registrationReport}
        onShowReport={() => onToggleReport(true)}
      />
      <PublishButton
        revision={revision}
        disabledDueToError={publishButtonDisabled}
        showWithWarning={publishButtonWithWarning}
      />
    </>
  );
}
