import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useCurrentScene } from "@/modes/mode-data-context";
import { useCached3DObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { useBoxControlsClippingPlanes } from "@/utils/box-controls-context";
import { downloadFile } from "@/utils/download";
import { volumeFromPlanes } from "@/utils/volume-utils";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { assert, extractOrthophoto } from "@faro-lotv/lotv";
import {
  selectDatasetOrArea,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { useCallback, useMemo } from "react";
import { Matrix4, Quaternion, Vector3 } from "three";
import { useOrthophotoViewDirection } from "./use-orthophoto-view-direction";

/**
 * @returns A callback to generate and download an orthophoto
 * @param onProcessingChanged Callback executed when the processing starts/finishes
 * @param onProgressChanged Callback reporting percentage of completion
 */
export function useExportOrthophoto(
  onProcessingChanged: (isProcessing: boolean) => void,
  onProgressChanged: (percentage: number) => void,
): () => Promise<void> {
  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "The overview image preview requires a point cloud",
  );

  const dataset = useAppSelector(selectDatasetOrArea(main));

  const transform = useAppSelector(selectIElementWorldTransform(main.id));
  const pointCloud = useCached3DObject(main);

  const clippingPlanesBox = useBoxControlsClippingPlanes();

  // Update the camera position and orientation to frame the pointcloud
  const volume = useMemo(() => {
    if (!clippingPlanesBox) return;

    const v = volumeFromPlanes(clippingPlanesBox, transform);
    if (!v?.position || !v.rotation || !v.size) return;

    return {
      position: new Vector3(v.position.x, v.position.y, v.position.z),
      quaternion: new Quaternion(
        v.rotation.x,
        v.rotation.y,
        v.rotation.z,
        v.rotation.w,
      ),
      size: new Vector3(v.size.x, v.size.y, v.size.z),
    };
  }, [clippingPlanesBox, transform]);

  // View direction of the camera
  const viewDir = useOrthophotoViewDirection(volume);

  const { handleErrorWithToast } = useErrorHandlers();

  return useCallback(async () => {
    if (!volume || !viewDir) {
      handleErrorWithToast({
        title: "Failed to generate the orthophoto",
        error: "Invalid volume",
      });
      return;
    }

    onProcessingChanged(true);
    try {
      const url = await extractOrthophoto(
        pointCloud,
        new Matrix4().fromArray(transform.worldMatrix),
        volume,
        {
          viewDir,
          up: new Vector3(0, 1, 0),
          north: new Vector3(1, 0, 0),
        },
        onProgressChanged,
      );

      downloadFile(url, `${dataset.name}.png`);
    } catch (e) {
      handleErrorWithToast({
        title: "Failed to generate the orthophoto",
        error: e,
      });
    }

    onProcessingChanged(false);
  }, [
    dataset,
    onProcessingChanged,
    onProgressChanged,
    handleErrorWithToast,
    pointCloud,
    transform,
    viewDir,
    volume,
  ]);
}
