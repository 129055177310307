import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectExclusiveModeCompletionAction } from "@/store/ui/ui-selectors";
import { setExclusiveModeCompletionAction } from "@/store/ui/ui-slice";
import {
  FaroButton,
  FaroText,
  TranslateVar,
  neutral,
} from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { OrthophotoPreview } from "./orthophoto-preview";
import { useExportOrthophoto } from "./use-export-orthophoto";

type OrthophotoExportFormProps = {
  /** Callback when the export state changes. */
  onChangeIsExportInProgress(isExportInProgress: boolean): void;
};

/** @returns the form to export an orthophoto from */
export function OrthophotoExportForm({
  onChangeIsExportInProgress,
}: OrthophotoExportFormProps): JSX.Element {
  const isProcessing = !!useAppSelector(selectExclusiveModeCompletionAction);

  // Disable the header bar by setting the exclusive mode action when generating the orthophoto
  const dispatch = useAppDispatch();
  const updateSpinner = useCallback(
    (isProcessing: boolean) => {
      onChangeIsExportInProgress(isProcessing);
      // TODO: remove me once we will be able to discard an orthophoto generation
      dispatch(
        setExclusiveModeCompletionAction(isProcessing ? "apply" : undefined),
      );
    },
    [dispatch, onChangeIsExportInProgress],
  );

  const [percentage, setPercentage] = useState(0);
  const exportOrthophoto = useExportOrthophoto(updateSpinner, setPercentage);

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack gap={2}>
        <FaroText variant="bodyM">
          To change the side where to project the points click on the desired
          face of the box.
        </FaroText>

        <FaroText variant="labelM">Orthophoto preview</FaroText>
        <OrthophotoPreview />
        <FaroText variant="bodyS" color={neutral[600]}>
          For a faster experience, consider exporting thin sections and keeping
          the browser page visible on screen
        </FaroText>
      </Stack>

      <FaroButton
        disabled={isProcessing}
        isLoading={isProcessing}
        onClick={exportOrthophoto}
        aria-label="start orthophoto export"
      >
        Export
        <TranslateVar name="Export percentage">{`${isProcessing ? ` ${percentage}%` : ""}`}</TranslateVar>
      </FaroButton>
    </Stack>
  );
}
