import { PointCloudObject } from "@/object-cache";
import {
  PointCloudAnalysis,
  PointCloudAnalysisColormap,
  PointCloudAnalysisLabel,
  PointCloudAnalysisPlane,
  ReferencePlaneType,
} from "@/store/point-cloud-analysis-tool-slice";
import { generateGUID } from "@faro-lotv/foundation";
import {
  averageUnsignedDistance,
  ColormapParameters,
  findPlanes,
  getLotvMath,
  PointsSelectionResult,
  selectPointsByPolygon,
  selectPointsInSphere,
} from "@faro-lotv/lotv";
import { Camera } from "@react-three/fiber";
import { Color, Plane, Sphere, Vector3 } from "three";

/**
 * Adjust the polygon selection points to be coplanar following these steps:
 *  - A set of maximal 50k points from the point cloud are selected using the polygon
 *  - Try to find max 2 planes in the selected points using RANSAC method
 *  - Choose the plane with the smallest average unsigned distance to the original polygon
 *  - The original polygon points are projected onto the plane, and returned as result
 *
 * @param points Polygon points to be adjusted
 * @param pointCloud The point cloud object
 * @returns Adjusted polygon points
 */
export async function adjustPointsToBeCoplanar(
  points: Vector3[] | undefined,
  pointCloud: PointCloudObject,
): Promise<Vector3[] | undefined> {
  // no adjustment needed if less than 3 points
  if (!points || points.length < 3) return points;

  // Do a rough selection of points by the polygon
  //  - use a big plane threshold (1 meter) to cover more area
  //  - limit the number of points to 50k
  //  - limit the point density to 5mm
  const selection = await selectPointsByPolygon(pointCloud, {
    polygon: points,
    planeThreshold: 1.0,
    maxNumberOfPoints: 50_000,
    minPointDensity: 5,
  });
  if (!selection) return points;

  // A much smaller RANSAC threshold is used to fit plane to the selected points
  // +/-5mm is a reasonable threshold for a flat surface, roughly 2 times the point density
  // Try to find max 2 planes from the selection
  const PLANE_RANSAC_THRESHOLD = 0.005;
  const fitPlanes = await findPlanes(
    selection.points,
    PLANE_RANSAC_THRESHOLD,
    true,
    2,
    0.1,
  );
  if (!fitPlanes || fitPlanes.length < 1) return points;

  // Create reference plane, offset result to the origin
  let refPlane = new Plane().setFromNormalAndCoplanarPoint(
    fitPlanes[0].normal,
    fitPlanes[0].point.add(selection.origin),
  );

  // If found 2 planes, choose the one with the smallest average unsigned distance
  if (fitPlanes.length === 2) {
    const plane = new Plane().setFromNormalAndCoplanarPoint(
      fitPlanes[1].normal,
      fitPlanes[1].point.add(selection.origin),
    );
    if (
      averageUnsignedDistance(plane, points) <
      averageUnsignedDistance(refPlane, points)
    ) {
      refPlane = plane;
    }
  }

  return points.map((p) => refPlane.projectPoint(p, new Vector3()));
}

/**
 * The planar threshold used to select points for analysis.
 * The selected points will be kept between two parallel planes with this distance.
 */
export const POLYGON_SELECTION_PLANE_THRESHOLD = 0.1;

/**
 * Create a new point cloud analysis from a polygon selection.
 *
 * @param pointcloud Point cloud object to analyze.
 * @param polygon Polygon defining the region to analyze.
 * @param camera Current camera.
 * @returns New analysis object.
 */
export async function createNewAnalysis(
  pointcloud: PointCloudObject,
  polygon: Vector3[],
  camera: Camera,
): Promise<PointCloudAnalysis> {
  const cameraDirection = camera.getWorldDirection(new Vector3());

  const referencePlaneType = ReferencePlaneType.flatness;
  const selection = await selectPointsByPolygon(pointcloud, {
    polygon,
    planeThreshold: POLYGON_SELECTION_PLANE_THRESHOLD,
    maxNumberOfPoints: 1_000_000,
    minPointDensity: 3,
  });

  // The collected points are not saved for later use. It is more efficient to compute
  // both flatness and plumbness planes here. Then there will be no need to re-collect
  // points and do computation again when user switch options later.
  const fittedPlane = await computeAnalysisFitPlane(
    selection,
    ReferencePlaneType.flatness,
    cameraDirection,
  );
  // using the fitted plane center as the initial elevation
  const elevation = fittedPlane?.point[1] ?? 0;
  const fittedPlumbPlane = await computeAnalysisFitPlane(
    selection,
    ReferencePlaneType.plumbness,
    cameraDirection,
  );

  return {
    id: generateGUID(),
    polygonSelection: polygon.map((p) => p.toArray()),
    tolerance: 0.005,
    parentId: pointcloud.iElement.id,
    referencePlaneType,
    colormap: pointCloudAnalysisColormapPresets.rainbow,
    isVisible: true,
    elevation,
    fittedPlane,
    fittedPlumbPlane,
    isDirty: true,
    labels: [],
  };
}

/**
 * Compute the best-fit plane of a point cloud region defined by a polygon.
 *
 * @param selection The point cloud selection to analyze.
 * @param referencePlaneType The type of reference plane to compute.
 * @param cameraWorldDirection The world space direction in which the camera is looking when the analysis is created by user.
 * @returns The normal and point of the best-fit plane.
 */
async function computeAnalysisFitPlane(
  selection: PointsSelectionResult | undefined,
  referencePlaneType: ReferencePlaneType,
  cameraWorldDirection: Vector3,
): Promise<PointCloudAnalysisPlane | undefined> {
  if (!selection) return;

  const lotvMath = await getLotvMath();
  const fitResult =
    referencePlaneType === ReferencePlaneType.plumbness
      ? lotvMath.fitPlanePerpendicularTo(selection.points, "XZ")
      : lotvMath.fitPlane(selection.points);
  if (!fitResult) return;

  const normal = new Vector3(
    fitResult.normal.x,
    fitResult.normal.y,
    fitResult.normal.z,
  );
  // correct plane normal to be pointing to the camera
  if (normal.dot(cameraWorldDirection) > 0) {
    normal.negate();
  }

  const point = new Vector3(
    fitResult.point.x + selection.origin.x,
    fitResult.point.y + selection.origin.y,
    fitResult.point.z + selection.origin.z,
  );

  return { normal: normal.toArray(), point: point.toArray() };
}

/**
 * Compute the reference plane for a point cloud analysis.
 *
 * @param analysis The point cloud analysis to compute the reference plane for.
 * @returns The normal and point of the reference plane.
 */
export function getAnalysisReferencePlane(
  analysis: PointCloudAnalysis,
): { normal: Vector3; point: Vector3 } | undefined {
  const fittedPlane =
    analysis.referencePlaneType === ReferencePlaneType.plumbness
      ? analysis.fittedPlumbPlane
      : analysis.fittedPlane;

  if (!fittedPlane) return;

  const point = new Vector3().fromArray(fittedPlane.point);
  const normal = new Vector3().fromArray(fittedPlane.normal);

  if (analysis.referencePlaneType === ReferencePlaneType.levelness) {
    normal.set(0.0, 1.0, 0.0);
  } else if (analysis.referencePlaneType === ReferencePlaneType.elevation) {
    normal.set(0.0, 1.0, 0.0);
    point.setY(analysis.elevation);
  }

  return { normal, point };
}

/**
 * Enum defines list of presets of colormaps which can be used for point clouds analysis
 */
export enum PointCloudAnalysisColormapPreset {
  rainbow = "rainbow",
  grayscale = "grayscale",
  blueGreenRed = "blueGreenRed",
  redGreenRed = "redGreenRed",
}

export type PointCloudAnalysisColormapPresetKey =
  keyof typeof PointCloudAnalysisColormapPreset;

/**
 * @param name The name to check
 * @returns True if the given name string is a colormap preset name
 */
export function isColormapPresetName(
  name: string,
): name is PointCloudAnalysisColormapPresetKey {
  return name in PointCloudAnalysisColormapPreset;
}

/**
 * Check if two colormaps are equal
 *
 * @param colormap1 The first colormap to compare
 * @param colormap2 The second colormap to compare
 * @returns True if the two colormaps are equal
 */
export function areColormapsSame(
  colormap1: PointCloudAnalysisColormap,
  colormap2: PointCloudAnalysisColormap,
): boolean {
  if (colormap1.length !== colormap2.length) return false;
  for (let i = 0; i < colormap1.length; i++) {
    if (colormap1[i].value !== colormap2[i].value) return false;
    if (colormap1[i].color !== colormap2[i].color) return false;
  }
  return true;
}

/**
 * List of pre-defined colormap colors.
 *
 * Note:
 * - Two consecutive equal values indicates a color jump at this value.
 * - values go from 0.0 (minimum range) to 1.0 (maximum range)
 * - value 0.5 is 0.0 deviation IF minium and maximum absolute deviations are equal
 */
export const pointCloudAnalysisColormapPresets: Record<
  PointCloudAnalysisColormapPresetKey,
  PointCloudAnalysisColormap
> = {
  rainbow: [
    { value: 0.0, color: "#FF00FF" },
    { value: 0.0, color: "#0000FF" },
    { value: 0.25, color: "#00FFFF" },
    { value: 0.5, color: "#00FF00" },
    { value: 0.75, color: "#FFFF00" },
    { value: 1.0, color: "#FF5400" },
    { value: 1.0, color: "#FF0000" },
  ],
  grayscale: [
    { value: 0.0, color: "#15191F" },
    { value: 1.0, color: "#F8F9FA" },
  ],
  blueGreenRed: [
    { value: 0.0, color: "#0000FF" },
    { value: 0.0, color: "#00FF00" },
    { value: 1.0, color: "#00FF00" },
    { value: 1.0, color: "#FF0000" },
  ],
  redGreenRed: [
    { value: 0.0, color: "#FF0000" },
    { value: 0.0, color: "#00FF00" },
    { value: 1.0, color: "#00FF00" },
    { value: 1.0, color: "#FF0000" },
  ],
};

/**
 * Compute the colormap parameters for a point cloud analysis.
 *
 * @param pointcloud The point cloud object to analyze.
 * @param analysis The point cloud analysis to compute the colormap parameters for.
 * @returns The colormap parameters.
 */
export function computeColormapParameters(
  pointcloud: PointCloudObject,
  analysis: PointCloudAnalysis,
): ColormapParameters | undefined {
  const referencePlane = getAnalysisReferencePlane(analysis);
  if (!referencePlane) return;

  pointcloud.updateMatrixWorld();
  const transform = pointcloud.matrixWorld.clone().invert();
  const polygon = analysis.polygonSelection.map((p) =>
    new Vector3().fromArray(p),
  );

  const colormap = analysis.colormap.map(({ value, color }) => ({
    value,
    color: new Color(color),
  }));

  return {
    planeNormal: referencePlane.normal,
    planePoint: referencePlane.point,
    polygon,
    colormap,
    minDeviation: -analysis.tolerance,
    maxDeviation: analysis.tolerance,
    transform,
  };
}

/**
 * Create a new analysis label at a given position.
 *
 * @param pointcloud Point cloud object associated with the analysis.
 * @param position Label position.
 * @returns New analysis label object.
 */
export function createAnalysisLabel(
  pointcloud: PointCloudObject,
  position: Vector3,
): PointCloudAnalysisLabel | undefined {
  // Compute deviation using an average of points within 1 inch radius
  const selectionRadius = 0.0254;
  const sphere = new Sphere(position, selectionRadius);
  const selection = selectPointsInSphere(pointcloud, {
    sphere,
    maxNumberOfPoints: 1000,
    minPointDensity: 3,
  });
  if (!selection) return;

  const average = new Vector3();
  const point = new Vector3();
  const nbPoints = selection.points.length / 3;
  for (let i = 0; i < nbPoints; i++) {
    point.fromArray(selection.points, i * 3);
    average.add(point);
  }
  average.divideScalar(nbPoints);
  average.add(selection.origin);

  return {
    id: generateGUID(),
    position: average.toArray(),
  };
}
