import { useProjectUnitOfMeasure } from "@/components/common/unit-of-measure-context";
import { useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { FaroText, FontWeights, neutral } from "@faro-lotv/flat-ui";
import { Optional } from "@faro-lotv/foundation";
import { IElementMeasurePolygon } from "@faro-lotv/ielement-types";
import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Vector3 } from "three";
import { computeMeasurementToDisplay } from "../../measurements/measure-utils";

type MeasurementValuesFieldProps = {
  /** The measurement whose values we want to show to the user */
  measurement: Pick<IElementMeasurePolygon, "points" | "isClosed"> &
    Optional<Pick<IElementMeasurePolygon, "id">, "id">;
};

/** @returns A stack containing the info about the input measurement (used in annotations) */
export function MeasurementValuesField({
  measurement,
}: MeasurementValuesFieldProps): JSX.Element {
  const { unitOfMeasure } = useProjectUnitOfMeasure();

  const worldMatrix = useAppSelector(
    selectIElementWorldMatrix4(measurement.id),
  );
  const points = useMemo(
    () =>
      measurement.points.map((p) =>
        new Vector3(p.x, p.y, p.z).applyMatrix4(worldMatrix),
      ),
    [measurement.points, worldMatrix],
  );
  const { perimeter, area, verticalDistance, horizontalDistance, x, y, z } =
    useMemo(() => {
      const components = computeMeasurementToDisplay(
        points,
        measurement.isClosed,
        unitOfMeasure,
      );
      return {
        perimeter: components.fullLength,
        area: components.area,
        verticalDistance: components.verticalDistance,
        horizontalDistance: components.horizontalDistance,
        x: components.x,
        y: components.y,
        z: components.z,
      };
    }, [measurement.isClosed, points, unitOfMeasure]);

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid item xs={12}>
        <FaroText
          variant="labelL"
          sx={{ color: neutral[300], pb: 1, fontWeight: FontWeights.Bold }}
        >
          Measurement values
        </FaroText>
      </Grid>
      {area && (
        <Grid item xs={4}>
          <FaroText variant="heading12" color={neutral[0]}>
            Area
          </FaroText>
          <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
            {area}
          </FaroText>
        </Grid>
      )}
      <Grid item xs={verticalDistance === undefined ? 8 : 4}>
        <FaroText variant="heading12" color={neutral[0]}>
          {measurement.isClosed ? "Perimeter" : "Length"}
        </FaroText>
        <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
          {perimeter}
        </FaroText>
      </Grid>
      {horizontalDistance === undefined ? null : (
        <Grid item xs={4}>
          <FaroText variant="heading12" color={neutral[0]}>
            Horizontal
          </FaroText>
          <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
            {horizontalDistance}
          </FaroText>
        </Grid>
      )}
      {verticalDistance === undefined ? null : (
        <Grid item xs={4}>
          <FaroText variant="heading12" color={neutral[0]}>
            Vertical
          </FaroText>
          <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
            {verticalDistance}
          </FaroText>
        </Grid>
      )}
      {x !== undefined && (
        <Grid item xs={4}>
          <FaroText variant="heading12" color={neutral[0]}>
            X
          </FaroText>
          <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
            {x}
          </FaroText>
        </Grid>
      )}
      {y !== undefined && (
        <Grid item xs={4}>
          <FaroText variant="heading12" color={neutral[0]}>
            Y
          </FaroText>
          <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
            {y}
          </FaroText>
        </Grid>
      )}
      {z !== undefined && (
        <Grid item xs={4}>
          <FaroText variant="heading12" color={neutral[0]}>
            Z
          </FaroText>
          <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
            {z}
          </FaroText>
        </Grid>
      )}
    </Grid>
  );
}
