import {
  getRegistrationReportError,
  MultiRegistrationReport,
  RegistrationError,
} from "@/registration-tools/utils/multi-registration-report";
import { FaroButton } from "@faro-lotv/flat-ui";
import { isLoading, MaybeLoading } from "@faro-lotv/foundation";
import {
  RegistrationRevision,
  RegistrationState,
} from "@faro-lotv/service-wires";

type ShowQualityReportButtonProps = {
  /** The revision to publish */
  revision: RegistrationRevision;

  /** The registration report of the active revision. */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Callback, when the report should be opened */
  onShowReport(): void;
};

/**
 * @returns A button to show and hide the registration report.
 */
export function ShowQualityReportButton({
  revision,
  registrationReport,
  onShowReport,
}: ShowQualityReportButtonProps): JSX.Element | null {
  const isEnabled =
    getRegistrationReportError(registrationReport) ===
    RegistrationError.NoError;

  if (
    !registrationReport ||
    (revision.state !== RegistrationState.registered &&
      revision.state !== RegistrationState.merged)
  ) {
    return null;
  }

  return (
    <FaroButton
      variant="ghost"
      isLoading={isLoading(registrationReport)}
      disabled={!isEnabled}
      onClick={onShowReport}
    >
      Show quality report
    </FaroButton>
  );
}
